import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskModule, IConfig } from 'ngx-mask'

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelect2Module,
		NgbModule,
		DataTablesModule,
		NgxMaskModule.forRoot(),
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelect2Module,
		NgbModule,
		DataTablesModule,
		NgxMaskModule,
	]
})
export class FormModule { }

